<template>
    <div class="uk-container uk-container-large">
        <ol class="list unload">
            <item
                :key="item.id"
                :item="item"
                :ride="ride"
                :updating="unloading"
                action="unload"
                @unloadAll="unloadAll(item)"
                @unloadLine="unloadLine($event)"
                class="item-spacing"
            />
            <button class="go-back" @click="goBack">
                <i class="fal fa-chevron-left" />
            </button>
        </ol>
    </div>
</template>

<style src="./LoadUnload.scss" lang="scss">

</style>

<script>
import { apiClient } from '@/api';
import Item          from './Item';
import {
    ITEM_TYPE,
    RIDE_STATUS,
    SINGLE_RIDE_INCLUDES
} from '@/constants';

export default {
    name:       "UnloadView",
    components: { Item },
    data() {
        return {
            item:      this.$route.params.item,
            ride:      this.$route.params.ride,
            unloading: null,
        }
    },
    computed: {
        packingSlip() {
            if (! this.item.type || this.item.type === ITEM_TYPE.PACKING_SLIP) return this.item.packingSlip;
            else                                                               return null;
        },
        purchaseReceipt() {
            if (! this.item.type || this.item.type === ITEM_TYPE.PURCHASE_RECEIPT) return this.item.purchaseReceipt;
            else                                                                   return null;
        }
    },
    methods:    {
        goBack()
        {
            this.$router.push({
                name: 'options_view',
                params: {
                    item:   this.item,
                    itemId: this.item.id,
                    ride:   this.ride
                }
            });
        },
        async unloadAll(item) {
            try {
                this.unloading = 'all';

                if (this.packingSlip) {
                    const response = await apiClient.put(`transport/packing_slips/${item.packingSlip.id}/unload`);

                    this.$set(this.item, 'packingSlip', response.data.data);
                } else {
                    // Might me implemented in the future
                }

            } catch (e) {
                console.error(e); //eslint-disable-line no-console
                throw e;
            } finally {
                this.unloading = null;
            }
        },
        async unloadLine(line) {
            try {
                this.unloading = line.id;

                const response = await apiClient.put(`transport/packing_slips/${line.packingSlip}/lines/${line.id}/unload`);

                const lines = this.item.packingSlip.lines;
                for (let i = 0; i < lines.length; i++) {
                    if (lines[i].id === line.id) {
                        lines.splice(i, 1, response.data.data);
                        break;
                    }
                }

                await this.updateStatus();
            } catch (e) {
                console.error(e); //eslint-disable-line no-console
                throw e;
            } finally {
                this.unloading = null;
            }
        },
        async updateStatus() {
            if (this.ride.status.code < RIDE_STATUS.IN_TRANSIT) {
                try {
                    const response = await apiClient.put(
                        `transport/transport_rides/${this.ride.id}?include=${SINGLE_RIDE_INCLUDES}`,
                        { status: RIDE_STATUS.IN_TRANSIT }
                    );

                    this.$set(this, 'ride', response.data.data);
                } catch (e) {
                    console.error(e); //eslint-disable-line no-console
                    throw e;
                }
            }
        }
    },
};
</script>
